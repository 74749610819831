<template>
  <v-container>
    <v-tabs
      v-model="selectedTab"
      class="tabs-border mb-4"
      color="gray13"
      background-color="transparent"
      height="46"
    >
      <v-tab
        v-for="item in $t('panel.transactionsPage.tabs')"
        :key="item.id"
        :ripple="false"
        @change="setSelectedTab(item.id)"
      >
        <span
          class="font-regular-16 gray7--text tab-item"
          :class="{ 'gray13--text': selectedTab === item.id }"
          >{{ item.title }}</span
        >
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedTab">
      <transition mode="out-in" name="route">
        <component :is="tabComponentName" />
      </transition>
    </v-tabs-items>
  </v-container>
</template>

<script>
const WithdrawTable = () =>
  import(
    /* webpackChunkName: "withdrawal-transactions-table" */ "@/components/Transactions/WithdrawalTransactions/WithdrawalTransactionsTable"
  );
const DepositTable = () =>
  import(
    /* webpackChunkName: "deposit-transactions-table" */ "@/components/Transactions/DepositTransactions/DepositTransactionsTable"
  );
const RefundTable = () =>
  import(
    /* webpackChunkName: "refund-transactions-table" */ "@/components/Transactions/RefundTransactions/RefundTransactionsTable"
  );

export default {
  name: "transactions-list",
  components: {
    WithdrawTable,
    DepositTable,
    RefundTable,
  },
  props: [],
  data() {
    return {
      selectedTab: 0,
    };
  },
  computed: {
    tabComponentName() {
      let tabName;
      switch (this.selectedTab) {
        case 0:
          tabName = "DepositTable";
          break;
        case 1:
          tabName = "WithdrawTable";
          break;
        case 2:
          tabName = "RefundTable";
          break;
      }
      return tabName;
    },
  },
  mounted() {},
  methods: {
    setSelectedTab(itemId) {
      this.selectedTab = itemId;
      this.$router.replace({ query: {} });
    },
  },
};
</script>

<style scoped lang="scss">
@import "TransactionsList";
</style>
